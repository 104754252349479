'use strict';
var core = require('core/components/modal');

core.onShownBsModal = function() {
  $('body').on('shown.bs.modal', '.modal', function() {
      $('body').trigger('modal:loaded', this);
  });
}

core.onModalLoaded = function() {
  $('body').on('modal:loaded', function(event, modal) {
      var $modal = $(modal);

      // Check for modal-scrollbody helper class and adjust body max-height if found
      if ($modal.find('.modal-scrollbody').length
          || $modal.find('.quick-view-dialog').length
          || $modal.find('.choose-bonus-product-dialog').length) {
          core.setModalBodyMaxHeight($modal);
      }
  });
}

core.initCustomModal = function() {
  $('body').on('click', '.custom-modal', function (e) {
      e.preventDefault();
      var cid = $(this).attr('data-cid');
      var title = $(this).attr('data-title');
      var dialogClasses = $(this).attr('data-dialog-classes');
      var selectedValueUrl = $(this).attr('href');

      core.getModalHtmlElement('customModal', dialogClasses);
      core.fillModalElement(selectedValueUrl, {cid: cid, title: title}, $('#customModal'));
  });
}

/**
 * Generates the modal window on the first call.
 *
 */
core.getModalHtmlElement = function(elementID, dialogClasses) {
  var id = elementID || 'customModal';
  dialogClasses = dialogClasses || '';

  if ($('#' + id).length !== 0) {
      $('#' + id).remove();
  }
  var htmlString = '<div class="modal fade" id="' + id + '" role="dialog">'
      + '<span class="enter-message sr-only"></span>'
      + '<div class="modal-dialog ' + dialogClasses + '">'
      + '<!-- Modal content-->'
      + '<div class="modal-content">'
      + '<div class="modal-body"></div>'
      + '</div>'
      + '</div>'
      + '</div>';
  $('body').append(htmlString);
}

function init() {
  core.onShownBsModal();
  core.onModalLoaded();
  core.initCustomModal();
}

module.exports = {
  init: init
};